import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



Vue.use(ElementUI);
Vue.use(BaiduMap, {
    ak: "xHKmxOQolchK3CU3zGpekfwwShteyzwf"
})

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {

    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {


        // console.log(location);
        let url = ''
        if (location.pathname == '/') {
            url = 'https://www.ouruihealth.com/mobile/#' + location.pathname + location.search;
            // url = 'http://192.168.100.8:8081/#' + location.pathname + location.search;

        } else {
            url = 'https://www.ouruihealth.com/mobile/#/pages' + location.pathname + location.pathname + location.search;
            // url = 'http://192.168.100.8:8081/#/pages' + location.pathname + location.pathname + location.search;

        }


        location.href = url;


    }
    next();

});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
